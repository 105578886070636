import React from 'react';
import Markdown from 'react-markdown';
import Iframe from 'react-iframe';

const ConsultancyPage = ({page}) => {
    return (
      <div className="container">
        <h1 className="heading--one">{page.title}</h1>
        <div className="body">
            <Markdown source={page.body} />
            <img src={page.image.url} />
            <Iframe
                id="booking-iframe"
                url={page.schedulingUrl}
                style="border: none; max-height: none; overflow: hidden; height: 405px !important;"
                height="800"
                width="100%"
            />
        </div>

        <style jsx>{`
          .container {
            padding: 20px 40px;
          }
          .body {

          }
        `}</style>
        </div>
    )
};

export default ConsultancyPage;
