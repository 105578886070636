import React from 'react';
import {graphql} from 'gatsby';

import ConsultancyPage from "../components/ConsultancyPage";

export default function ContentfulConsultancyPage({data, pageContext}) {
    if (pageContext.ontId === undefined) {
        return null;
    }

    const page = data.contentful.shopConsultancyPageCollection.items[0];

    return <ConsultancyPage page={page} />;
}


export const query = graphql`
    query shopConsultancyPageCollection($ontId: String){
        contentful {
            shopConsultancyPageCollection: shopConsultancyPageCollection(where:{ontIdentifier: $ontId} limit: 1) {
                items {
                    title
                    body
                    schedulingUrl
                    image {
                        url
                    }
                }
            }
        }
    }
`
